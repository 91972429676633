<template>
  <b-breadcrumb>
    <b-breadcrumb-item
      v-for="(item, index) in breadCrumbItems"
      :key="index"
      :to="item.to ? item.to : ''"
      class="font-weight-bold"
      :active="item.active"
    >
      {{ item.name ? item.name : '' }}
    </b-breadcrumb-item>
  </b-breadcrumb>
</template>
<script>
import { BBreadcrumb, BBreadcrumbItem } from 'bootstrap-vue'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
  },
  props: {
    breadCrumbItems: {
      type: Array,
      default: null,
    },
  },
}
</script>
<style>
.breadcrumb-item.active{
  color: gray !important;
}
</style>
