<template>
  <b-modal
    id="change-payment-card"
    ref="payment-card-modal-1"
    v-model="showModal"
    centered
    hide-footer
    hide-header
    class="position-relative"
  >
    <div class="add-member-to-group d-flex justify-content-center align-content-center align-items-center modal1">
      <b-row
        class="d-block"
      >
        <div class="closediv">
          <b-button
            variant="transparent"
            class="closebtn"
            @click="hide()"
          >
            <feather-icon
              icon="XIcon"
              class="cursor-pointer"
              size="16"
            />
          </b-button>
        </div>
        <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center mt-2 mb-2">
          <b-form
            class="form-class-div"
            @submit.prevent
          >
            <b-col>
              <div class="text-center">
                <span class="mt-2 font-weight-bolder heading1">
                  Change Card
                </span>
              </div>
              <b-form-group>
                <label
                  for="card-number-id"
                  class="font-weight-bold"
                >Card number</label>
                <b-input-group class="input-group-merge">
                  <div
                    id="card-number-id"
                  />
                  <!--                  <div class="card-icon">-->
                  <!--                    <img-->
                  <!--                      src="@/assets/images/VISA.png"-->
                  <!--                      class="cursor-pointer"-->
                  <!--                      alt="card-icon"-->
                  <!--                    >-->
                  <!--                  </div>-->
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-row class="ml-0 mr-0">
              <b-col md="6">
                <b-form-group>
                  <label
                    for="card-expiry-id"
                    class="font-weight-bold"
                  >Validity</label>
                  <b-input-group class="input-group-merge">
                    <div id="card-expiry-id" />
                    <div class="card-icon">
                      <img
                        src="@/assets/images/Expiry.png"
                        class="cursor-pointer"
                        alt="expiry-icon"
                      >
                    </div>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label
                    for="card-cvc-id"
                    class="font-weight-bold"
                  >CVC</label>
                  <b-input-group class="input-group-merge">
                    <div id="card-cvc-id" />
                    <div class="card-icon">
                      <img
                        src="@/assets/images/CVC.png"
                        class="cursor-pointer"
                        alt="cvc-icon"
                      >
                    </div>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col>
                <div
                  id="card-error"
                  class="mt-1 mb-2"
                />
              </b-col>
            </b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 mb-2"
                style="width: inherit !important;"
                :disabled="lockSubmit"
                @click="createToken()"
              >
                <div
                  v-if="lockSubmit"
                  class="spinner"
                >
                  <b-spinner
                    small
                  />
                  <span class="sr-only">Loading...</span>
                </div>
                Change
              </b-button>
            </b-col>
          </b-form>
        </div>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BInputGroup,
  VBModal,
  BSpinner,

} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import Vue from 'vue'
import { showToast } from '@/common/global/functions'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    BModal,
    BRow,
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BSpinner,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      showModal: false,
      cardHolder: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      lockSubmit: false,
      invoiceId: null,
      amount: null,
      groupId: null,
      tokenData: {
        source: '',
      },
    }
  },
  mounted() {
    // this.show()
    // this.invoiceId = this.$route.query.invoiceId
    this.groupId = this.$route.query.groupId
  },
  methods: {
    async show(id, amount) {
      this.invoiceId = id
      this.amount = amount
      this.showModal = true
      // await this.$refs['payment-card-modal-1'].show()
      setTimeout(() => {
        this.stripeElements = this.$stripe.elements()
        this.stripeComponentMountFunc()
      }, 1)
    },
    async hide() {
      this.showModal = false
    },
    stripeComponentMountFunc() {
      const style = {
        base: {
          // color: 'black',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      }
      this.cardNumber = this.stripeElements.create('cardNumber', { showIcon: true, iconStyle: 'default', style })
      this.cardNumber.mount('#card-number-id')
      this.cardExpiry = this.stripeElements.create('cardExpiry', { style })
      this.cardExpiry.mount('#card-expiry-id')
      this.cardCvc = this.stripeElements.create('cardCvc', { style })
      this.cardCvc.mount('#card-cvc-id')
    },
    async createToken() {
      this.lockSubmit = true
      const { token, error } = await this.$stripe.createToken(this.cardNumber)
      if (error) {
        // handle error here
        document.getElementById('card-error').innerHTML = error.message
        this.$forceUpdate() // Forcing the DOM to update so the Stripe Element can update.
        this.lockSubmit = false
        return
      }
      await this.saveNewCard(token.id)
    },
    async saveNewCard(token) {
      try {
        this.tokenData.source = token
        await this.$axios.post('payment/add-card-customer', this.tokenData)
        await this.$store.dispatch('user/getUserIfNotExist', true)
        showToast('Change Payment Card', 'Payment card updated successfully!')
        if (this.invoiceId) {
          this.$swal({
            title:
                '<span class="font-weight-bolder text-primary">Invoice Payment</span>',
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/simiicons/Image(2).svg'),
            text: `Pay invoice amount $ ${this.amount}`,
            confirmButtonText: 'Pay',
            showLoaderOnConfirm: true,
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonAriaLabel: 'Thumbs down',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
              icon: 'primary',
            },
            buttonsStyling: false,
            preConfirm: () => this.$axios
              .post('payment/pay-now-invoice', { invoiceId: this.invoiceId })
              .then(() => {
                showToast('Invoice Payment', 'Transaction succeeded')
                this.$emit('getAllInvoicesOfUser')
                // this.$router.push({
                //   path: '/billing-details',
                //   query: { groupId: `${this.groupId}` },
                // })
                // window.location.reload()
              })
              .catch(() => {
                showToast('Invoice Payment', 'Your payment is declined ', 'danger', 4000)
              }),
            allowOutsideClick: () => !this.$swal.isLoading(),
          })
          // try {
          //   await this.$axios
          //     .post('payment/pay-now-invoice', { invoiceId: this.invoiceId })
          //     .then(() => {
          //       showToast('Invoice Payment', 'Transaction succeeded')
          //       this.$emit('getAllInvoicesOfUser')
          //     })
          // } catch {
          //   showToast('Invoice Payment', 'Your payment is declined ', 'danger', 4000)
          // }
        }
        await this.hide()
        this.lockSubmit = false
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        showToast('Change Card', message.toString(), 'danger')
        this.lockSubmit = false
      }
      this.lockSubmit = false
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
#change-payment-card {
  #card-number-id,
  #card-expiry-id,
  #card-cvc-id,
  #subscription{
    background: white;
    padding: 12px;
    border: 1px solid #d0d2d6;
    border-radius: 7px;
    width: 100%;
    position: relative;
  }
  .card-icon{
    position: absolute;
    right: 3%;
    top: 25%;
  }
  #custom-button{
    margin: 10px 0;
    display: block;
  }
  #card-error {
    color: red;
  }
  .form-class-div{
    width: 400px;
  }
}

</style>
