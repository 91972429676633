const MemberPermissions = {
  ALLOWED_APPS: 1,
  BANNER_CAMPAIGNS: 2,
  COVID: 3,
  DEVICE_GROUPS: 4,
  DEVICES: 5,
  GROUPS: 6,
  HOME_SCREEN_VIEWS: 7,
  MEDIA: 8,
  PREFERRED_COUNTRIES: 9,
  PROFILE: 10,
  PROFILE_HOME: 11,
  TARGET_ADS: 12,
  USER_HAS_GROUP: 13,
  USER: 14,
  WIDGETS: 15,
  MEMBERS: 16,
  SUPER_MEMBER: 17,
  ONE_SITE: 18,
  MULTIPLE_SITES: 19,
}
export default MemberPermissions
